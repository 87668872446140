import { useState } from 'react';
import axios from 'axios';
import ReactiveButton from 'reactive-button';
// import { AwesomeButton } from 'react-awesome-button';
// import AwesomeButtonStyles from 'react-awesome-button/src/styles/styles.scss';

const projectID = 'cf01a4c5-909d-4d94-8a26-298fc2123a82';

const Modal = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const authObject = { 'Project-ID': projectID, 'User-Name': username.toLowerCase(), 'User-Secret': password };

    try {
      await axios.get('https://api.chatengine.io/chats', { headers: authObject });
      localStorage.setItem('username', username);
      localStorage.setItem('password', password);
      window.location.reload();
    } catch (err) {
      setUsername('');
      setPassword('');
    }
  };

  return (
    <div className="wrapper">
      <canvas id="projector">Your browser does not support the Canvas element.</canvas>
      <div className="form">
        <h1 className="title">FibeChat </h1>
        <div className="subTitle">Chat, Share Photos with Individual or Group</div>
        <form onSubmit={handleSubmit}>
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value.toLowerCase())} className="input" placeholder="Username" required />
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="input" placeholder="Password" required />
          <div align="center">
            {/* <button type="submit" className="button">
              <span>Login</span>
            </button> */}
           <ReactiveButton
           type="submit"
        idleText="login"
        loadingText="Loading"
      successText="Done"
      size="large" 
      rounded
    />
            <br />
          </div>
        </form>
        <br></br>
        <div className="footer">Developed by Karthik Appaswamy (akarthikcan@gmail.com)</div>
      </div>
    </div>

  );
};

export default Modal;
